<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Caixas</h4>
          <b-button variant="secondary" @click="changeRouteNewBox">
            <b-icon icon="plus" />
            NOVA CAIXA
          </b-button>
        </div>
        <b-card-text class="d-flex flex-column">
          <b-table
            :items="boxes"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="changeRouteEditBox(row.item)"
              >
                <b-icon-pencil />
              </b-button>
              <b-button
                size="sm"
                variant="danger"
                @click="openRemoveBoxModal(row.item.id)"
              >
                <b-icon-trash icon="eye-slash" />
              </b-button>
            </template>
          </b-table>
          <b-button @click="getMore">
            <b-icon icon="arrow-clockwise" animation="spin" v-if="isLoading" />
            CARREGAR MAIS
          </b-button>
        </b-card-text>
      </b-col>
    </b-row>
    <custom-modal
      v-model="removeDialog"
      title="Tem certeza?"
      message="Deseja remover essa caixa."
      @confirm="deleteBox"
    />
  </b-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomModal from "../../components/shared/CustomModal.vue";

export default {
  components: { CustomModal },
  name: "listBox",
  data() {
    return {
      page: 2,
      removeDialog: false,
      idToRemove: null,
      fields: [
        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
        },
        {
          key: "altura",
          label: "Altura",
          sortable: true,
        },
        {
          key: "largura",
          label: "Largura",
          sortable: true,
        },
        {
          key: "comprimento",
          label: "Comprimento",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapState("box", ["boxes", "isLoading", "isLoadingSearch", "loadMore"]),
  },
  methods: {
    ...mapActions("box", ["getAllBoxes", "getMoreAllBoxes", "removeBox"]),
    ...mapMutations("box", ["setOneBox", "clearBoxValues"]),

    changeRouteNewBox() {
      this.clearBoxValues();
      this.$router.push(`caixa/criar`);
    },
    changeRouteEditBox(box) {
      this.setOneBox(box);
      this.$router.push(`caixa/${box.id}/editar`);
    },
    openRemoveBoxModal(id) {
      this.idToRemove = id;
      this.removeDialog = true;
    },
    async getMore() {
      const { page } = this;
      if (!this.loadMore) return;
      await this.getMoreAllBoxes(page);
      this.page++;
    },
    async deleteBox() {
      const { idToRemove } = this;
      await this.removeBox(idToRemove);
      this.removeDialog = false;
    },
  },
  async created() {
    await this.getAllBoxes();
  },
};
</script>
